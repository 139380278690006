import React from 'react'
import MainLayout from './layout/MainLayout'
import { Navigate, Route, Routes } from 'react-router-dom'
import Create from './pages/Create'
import Manage from './pages/Manage'
import Login from './components/Login'
import { useAuth } from './contexts/Auth'
import Track from './pages/Track'

function App() {
    const { logMeIn } = useAuth()
    return (
        <div>
            <MainLayout>
                <Routes>
                    <Route index element={<Navigate to='/create' />} />
                    <Route path='/create' element={<Create />} />
                    <Route path='/manage*' element={<Manage />} />
                    <Route path='/track*' element={<Track />} />
                </Routes>
            </MainLayout>

            <div className='h-screen bg-white w-full mt-20 flex justify-center items-center'>


                                we can have sections

                                and footer here

            </div>

            {logMeIn && <Login />}
        </div>
    )
}

export default App
