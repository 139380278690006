import React from 'react'
import { useTranslation } from 'react-i18next'
import { formateDate } from '../utils/formateDate'
import Loader from './Loader'
import { useNavigate } from 'react-router-dom'
import PaymentModal from './PaymentModal'

function Campaigns({ campaigns, loading }) {
  const [t] = useTranslation('global')


  return (
    <div className='flex flex-col gap-2 w-full'>
      <h2 className='font-semibold text-themeBlack-900'>{t('campaigns')}</h2>
      <div className='flex flex-col gap-2 w-full overflow-y-scroll no-scrollbar'>
        {loading &&
          <div className='flex justify-center items-center h-[90vh]'>
            <Loader />
          </div>
        }
        {campaigns.map((campaign) => (
          <Campaign campaign={campaign} />
        ))}
      </div>
    </div>
  )
}

export default Campaigns


const Campaign = ({ campaign }) => {
  const [t] = useTranslation('global')
  const [paymentRequired, setPaymentRequired] = React.useState(false)
  const naviagte = useNavigate()
  return (
    <>
      <div
        onClick={(e) => {
          if (campaign.status === 'Incomplete') {
            setPaymentRequired(true)
            return
          }
          naviagte(`/manage/${campaign._id}`)
        }}
        className='flex gap-2 p-3 rounded-md border border-themeGray-400 w-full cursor-pointer'>
        <img src={campaign.image} className='h-20 w-20 object-cover rounded-md' />
        <div className='flex flex-col gap-1 w-full'>
          <h1 className='text-themeBlack-900 text-sm font-medium truncate'>{campaign.product_name}</h1>
          <div className='flex justify-between items-center w-full'>
            <div className='flex gap-1'>
              {campaign.shorts && <img src='/shorts.png' className='w-3' />}
              {campaign.reels && <img src='/insta.png' className='w-3' />}
              {campaign.tiktok && <img src='/tiktok.png' className='w-3' />}
            </div>
            <p className='text-themeBlack-100 text-xs'>{campaign.status}</p>
          </div>
          <div className='flex justify-between items-center w-full'>
            <p className='text-themeBlack-100 text-xs'>#{campaign.campaign_id}</p>
            <p className='text-themeBlack-100 text-xs'>{campaign.creator_count} {t('creators')}</p>
          </div>
          <div className='flex justify-between items-center w-full'>
            <p className='text-themeBlack-100 text-xs'>{formateDate(campaign.start_date)}</p>
            <p className='text-themeBlack-100 text-xs'>{Number(campaign.grand_total)?.toLocaleString()} {t('won')}</p>
          </div>
        </div>
      </div>

      {paymentRequired &&
        <PaymentModal
          createdCampaign={{
            campaign: campaign,
            paymentID: campaign.paymentId
          }}
          setPaymentWindow={setPaymentRequired}
        />}
    </>
  )
}