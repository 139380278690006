import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { IoCreate } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';
import { MdTrackChanges } from 'react-icons/md';
import { useAuth } from '../contexts/Auth';

function Sidebar() {
  const [t] = useTranslation('global');

  const { loggedIn, setLogMeIn } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const redirect = (path) => {
    if(!loggedIn && path !== '/create'){
      setLogMeIn(true)
      return;
    }
    else{
      navigate(path)
    }
  }
  return (
    <div className='w-[250px]'>
      <h1 onClick={() => redirect('/create')} className='font-bold text-xl text-themeBlack-900'>Imfact<span className='font-medium text-sm'>.ai</span></h1>
      <span className='flex flex-col gap-2 mt-6 justify-start items-start'>
        <div onClick={() => redirect('/create')} className={`${location.pathname === '/create'? 'active' : ''} cursor-pointer text-base font-medium hover:bg-themeBlue-500 hover:shadow-sm hover:text-white leading-[30px] py-1 px-4 flex gap-2 w-full justify-start items-center rounded-md transition-all ease-in duration-100`}>
          <IoCreate className='text-2xl' />
          {t('create')}
        </div>
        <div onClick={() => redirect('/manage')} className={`${location.pathname === '/manage'? 'active' : ''} cursor-pointer text-base font-medium hover:bg-themeBlue-500 hover:shadow-sm hover:text-white leading-[30px] py-1 px-4 flex gap-2 w-full justify-start items-center rounded-md transition-all ease-in duration-100`}>
        <FaList className='text-xl' />
          {t('manage')}
        </div>
        <div onClick={() => redirect('/track')} className={`${location.pathname === '/track'? 'active' : ''} cursor-pointer text-base font-medium hover:bg-themeBlue-500 hover:shadow-sm hover:text-white leading-[30px] py-1 px-4 flex gap-2 w-full justify-start items-center rounded-md transition-all ease-in duration-100`}>
        <MdTrackChanges className='text-2xl' />
          {t('track')}
        </div>
      </span>
    </div>
  )
}

export default Sidebar
