import React from 'react'

function Loader() {
  return (
    <div class="loader-container">
        <div class="loader loader2">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default Loader
