import React, { useEffect } from 'react'
import Select from '../Select'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import TagInput from '../TagInput'
import Button from '../Button'
import { getCategories } from '../../services/getCategories'

function Column2({ data, setData, readOnly, generateWithAI, generating, generated }) {
    const [t] = useTranslation('global')
    const [disabled, setDisabled] = React.useState(true)
    const [categoris, setCategoris] = React.useState([])

    const onChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }



    useEffect(() => {
        if (data.product_url?.length > 1 && data.img && !generated) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }, [data, generated])

    useEffect(() => {
        getCategories(true, 'eng').then((res) => {
            setCategoris(res)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <div className='relative flex flex-col justify-between h-full w-full gap-4'>
            <div className='flex flex-col gap-4 w-full h-full pb-20 overflow-y-scroll no-scrollbar'>
                <Select
                    options={categoris}
                    onChange={onChange}
                    value={data.category}
                    name='category'
                    placeholder={t('selectCategory')}
                    label={t('category')}
                />
                <Input type='textarea' name='summary' onChange={onChange} readOnly={readOnly} value={data.summary} label={t('summary')} placeholder={t('entersummary')} />
                <Input type='text' name='product_name' onChange={onChange} readOnly={readOnly} value={data.product_name} label={t('product_name')} placeholder={t('enterProductName')} />
                <Input type='text' name='brand_name' onChange={onChange} readOnly={readOnly} value={data.brand_name} label={t('brand_name')} placeholder={t('enterBrandName')} />
                <TagInput readOnly={readOnly} name='hash_tags' value={data.hash_tags || []} onChange={onChange} label={t('tags')} />
                <Input type='textarea' name='key_point' onChange={onChange} readOnly={readOnly} value={data.key_point} label={t('key_point')} placeholder={t('key_pointPlace')} />
                <Input type='textarea' name='tips' onChange={onChange} readOnly={readOnly} value={data.tips} label={t('tips')} placeholder={t('tipsPlace')} />
                <TagInput readOnly={readOnly} name='account_tags' value={data.account_tags || []} onChange={onChange} label={t('account_tags')} />
                <Input type='textarea' name='caution' onChange={onChange} readOnly={readOnly} value={data.caution} label={t('caution')} placeholder={t('cautionPlace')} />
            </div>
            <div className='absolute bottom-0 w-full bg-white'>
                <Button text={t('genWithAI')} disabled={disabled} loading={generating} onClick={generateWithAI} />
            </div>
        </div>
    )
}

export default Column2
