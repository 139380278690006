
import React, { createContext, useContext, useState } from 'react'

const Context = createContext();


function Auth({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null)
  const [loggedIn, setLoggedIn] = useState(user ? true : false)
  const [logMeIn, setLogMeIn] = useState(false)


  return (
    <Context.Provider value={{ user, setUser, loggedIn, setLoggedIn, logMeIn, setLogMeIn }}>
      {children}
    </Context.Provider>
  )
}

export default Auth

export const useAuth = () => {
  return useContext(Context)
}