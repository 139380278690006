import React from 'react'
import { useAuth } from '../contexts/Auth'

function Input({
    name, 
    label,
    placeholder,
    type,
    required,
    value,
    onChange,
    readOnly,
    forcedEnabled = false
}) {
  const { loggedIn, setLogMeIn } = useAuth()
  const onClick = (e) => {
    if(!loggedIn && !forcedEnabled) {
      setLogMeIn(true)
    }
  }
  return (
    <div className='flex flex-col gap-1 w-full relative z-0'>
       {label && <label className='text-themeBlack-900 text-sm font-medium'>{label}</label>}
       { type === "textarea" ?
        <textarea
        name={name}
        type={type}
        required={required}
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder} 
        readOnly={forcedEnabled ? false : (readOnly || !loggedIn)}
        className='border-themeGray-400 resize-none min-h-6 outline-none border rounded-lg py-3 px-4 bg-white w-full placeholder:text-themeBlack-100/50 text-themeBlack-900 text-sm'
       
        >

        </textarea>
        :
        <input 
         name={name}
         type={type}
         required={required}
         value={value}
         onClick={onClick}
         onChange={onChange}
         placeholder={placeholder}
         readOnly={forcedEnabled ? false : (readOnly || !loggedIn)}
         className='border-themeGray-400 outline-none border rounded-lg py-3 px-4 bg-white w-full placeholder:text-themeBlack-100/50 text-themeBlack-900 text-sm'
        />

        }
    </div>
  )
}

export default Input
