import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { convertBase64 } from '../../utils/convertBase64'
import Button from '../Button'
import { useAuth } from '../../contexts/Auth'

function Column1({ data, setData, readOnly, generateWithAI, generating, generated }) {
  const [t,] = useTranslation("global")
  const [mode, setMode] = React.useState('')
  const inputRef = React.useRef(null)
  const [Image, setImage] = React.useState(null)
  const [disabled, setDisabled] = React.useState(true)
  const { loggedIn, setLogMeIn } = useAuth()

  const checkLogin = (e) => {
    e.stopPropagation()
    if (!loggedIn) {
      setLogMeIn(true)
    }
  }

  const handleImage = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setImage(base64)
    setData({ ...data, img: file })
  }

  useEffect(() => {
    if(data.product_url?.length > 1 && data.img && !generated){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  }, [data, generated])


  useEffect(() => {
    if(mode !== ''){
      if(mode === 'Creative'){
        setData((prev) => ({...prev, mode: 'Write a creatively'}))
      }
      if(mode === 'New Lanunch'){
        setData((prev) => ({...prev, mode: 'Write about New Launch'}))
      }
      if(mode === 'Emotional'){
        setData((prev) => ({...prev, mode: 'Write an Emotionally'}))
      }
    }
  }, [mode])

  

  return (
    <div className='flex flex-col justify-between h-full w-full gap-4'>
      <div className='flex flex-col gap-4 w-full'>
        <Input type='url' name='product_url' readOnly={readOnly} value={data.product_url} label={t('productUrl')} placeholder={t('enterProductUrl')} 
          onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
        />
        <div onClick={() => inputRef.current.click()} className='flex justify-center flex-col items-center cursor-pointer overflow-hidden w-full h-44 border-themeBlue-500 border rounded-lg border-dashed relative'>
          {Image ?
            <img src={Image} className='w-full h-full object-cover cursor-pointer' />
            :
            <>
              <AiOutlineCloudUpload className='text-themeBlue-500 text-[50px] cursor-pointer' />
              <p className='text-sm text-themeBlue-500 cursor-pointer'>{t('uploadImage')}</p>
            </>}
          <input ref={inputRef} onChange={handleImage} type={loggedIn ? 'file' : ''} className='absolute top-0 left-0 w-full h-full opacity-0' accept='image/*' onClick={checkLogin} />
        </div>

        <div className='flex gap-2 flex-row'>
          <button onClick={() => setMode("Creative")} className={`max-w-28 flex justify-center cursor-pointer items-center gap-2 font-medium w-full border ${mode === 'Creative' ? 'border-themeBlue-500 text-themeBlue-500' : 'border-themeGray-400 text-themeBlack-900'} rounded-lg py-3 text-sm`}>
            {t('Creative')}
          </button>
          <button onClick={() => setMode("New Lanunch")} className={`max-w-28 flex justify-center cursor-pointer items-center gap-2 font-medium w-full border ${mode === 'New Lanunch' ? 'border-themeBlue-500 text-themeBlue-500' : 'border-themeGray-400 text-themeBlack-900'} rounded-lg py-3 text-sm`}>
            {t('newLaunch')}
          </button>
          <button onClick={() => setMode("Emotional")} className={`max-w-28 flex justify-center cursor-pointer items-center gap-2 font-medium w-full border ${mode === 'Emotional' ? 'border-themeBlue-500 text-themeBlue-500' : 'border-themeGray-400 text-themeBlack-900'} rounded-lg py-3 text-sm`}>
            {t('Emotional')}
          </button>
        </div>

      </div>

        <Button text={t('genWithAI')} disabled={disabled} loading={generating} onClick={generateWithAI}/>
    </div>
  )
}

export default Column1
