import { CHAT_GPT_API } from '../constants'
import OpenAI from "openai";

const openai = new OpenAI({
    apiKey: CHAT_GPT_API,
    dangerouslyAllowBrowser: true
});

export const generateChatGPT = async (product_url, image, mode) => {
    const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            {
                role: "user",
                content: `
            I'm trying to market the product shown in this image: ${image}. 
            here is the URL: ${product_url}.
            Please provide:
            1. A one-sentence product summary.
            2. Five hashtags.
            3. Five key points to emphasize about the product.
            4. Cautions while creating a video.
            5. Tips for creating engaging marketing videos for this product.
            6. Decription of the product.
            remmber all texts hould be plain text, in korean language and ${mode}.
            `,
            },
        ],
    });

    const content = response?.choices?.[0]?.message?.content;
    const extractSections = (text) => {
        const regex = /(\d\.)\s([\s\S]*?)(?=\n\d\.|$)/g;
        const sections = {};
        let match;

        while ((match = regex.exec(text)) !== null) {
            const key = match[1].replace(".", "");
            const value = match[2].trim();
            sections[key] = value;
        }
        return sections;
    };

    const sections = extractSections(content);

    const productSummary = sections["1"].replace(/-/g, "").replace(/"/g, "");
    const hashtags = sections["2"].split(" ").map((h) => h.replace("#", ""));

    const keyPoints = sections["3"]
        .split("\n")
        .map((line) => line.replace(/^\- /, "").trim())
        .filter(Boolean)
        .join("\n")
        .replace(/-/g, "")
        .replace(/"/g, "");

    const cautions = sections["4"]
        .split("\n")
        .map((line) => line.replace(/^\- /, "").trim())
        .join("\n")
        .replace(/-/g, "")
        .replace(/"/g, "");

    const tips = sections["5"]
        .split("\n")
        .map((line) => line.replace(/^\- /, "").trim())
        .filter(Boolean)
        .join("\n")
        .replace(/-/g, "").replace(/"/g, "");

    const Decription = sections["6"]
        .split("\n")
        .map((line) => line.replace(/^\- /, "").trim())
        .filter(Boolean)
        .join("\n")
        .replace(/-/g, "").replace(/"/g, "");

    console.log("Product Summary:", productSummary);
    console.log("Hashtags:", hashtags);
    console.log("Key Points:", keyPoints);
    console.log("Subtitles:", cautions);
    console.log("Tips:", tips);
    console.log("Decription:", Decription);

    return {
        productSummary,
        hashtags,
        keyPoints,
        cautions,
        tips,
        Decription,
    }
}