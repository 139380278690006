import React, { useEffect, useState } from 'react';
import Input from './Input';
import { useTranslation } from 'react-i18next';
import Counter from './Counter';
import { formateDate } from '../utils/formateDate';

function Schedule({ readOnly, data, setData }) {
    const [t] = useTranslation('global');
    return (
        <>
            <Input
                type="date"
                readOnly={readOnly}
                value={data.start_date}
                name="start_date"
                label={t('start_date')}
                onChange={(e) =>
                    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
            />
            <div className="flex flex-col gap-1">
                <label className="text-themeBlack-900 text-sm font-medium">
                    {t('period')}
                </label>
                <Counter
                    value={data.period}
                    min={7}
                    max={14}
                    onChange={(e) =>
                        setData((prev) => ({ ...prev, [e.target.name]: e.target.value}))
                    }
                    name="period"
                    readOnly={readOnly}
                />
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex justify-between items-center">
                    <p className="text-themeBlack-900 text-sm font-medium">{t('start_date')}</p>
                    <p className="text-themeBlack-900 text-sm font-normal">{formateDate(data.start_date)}</p>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-themeBlack-900 text-sm font-medium">{t('submission')}</p>
                    <p className="text-themeBlack-900 text-sm font-normal">{formateDate(data.submission_date)}</p>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-themeBlack-900 text-sm font-medium">{t('buffer')}</p>
                    <p className="text-themeBlack-900 text-sm font-normal">{formateDate(data.buffer_date)}</p>
                </div>
            </div>
        </>
    );
}

export default Schedule;
