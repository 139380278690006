import React, { useEffect } from 'react'
import Select from '../Select'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import TagInput from '../TagInput'
import Button from '../Button'
import Counter from '../Counter'
import Sechudle from '../Sechudle'
import { useAuth } from '../../contexts/Auth'
import { createUpdateCampaign } from '../../services/createUpdateCampaign'
import PaymentModal from '../PaymentModal'
import { imageUpload } from '../../services/imageUpload'

function Column3({ data, setData, readOnly }) {
    const [t] = useTranslation('global')
    const [disabled, setDisabled] = React.useState(true)
    const [loading, setLoading] = React.useState(false)

    const [createdCampaign, setCreatedCampaign] = React.useState(null)
    const [paymentWindow, setPaymentWindow] = React.useState(null)
    const onChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const { loggedIn, setLogMeIn } = useAuth()


    useEffect(() => {
        if (
            data.product_url?.length > 1 &&
            // (data.image?.length > 1 || data.img) &&

            data.category?.length > 1 &&
            data.summary?.length > 1 &&
            data.product_name?.length > 1 &&
            data.brand_name?.length > 1 &&
            data.hash_tags?.length > 0 &&
            data.key_point?.length > 1 &&
            data.tips?.length > 1 &&
            data.caution?.length > 1 &&

            (data.shorts || data.reels || data.tiktok) &&
            data.creator_count >= 1 &&
            data.decription?.length > 1 &&
            data.delivery_item?.length > 1 &&
            new Date(data.start_date) > new Date() &&


            loggedIn
        ) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

        console.log(
            data.product_url?.length > 1,
            (data.image?.length > 1 || data.img),

            data.category?.length > 1,
            data.summary?.length > 1,
            data.product_name?.length > 1,
            data.brand_name?.length > 1,
            data.hash_tags?.length > 1,
            data.key_point?.length > 1,
            data.tips?.length > 1,
            data.caution?.length > 1,

            (data.shorts || data.reels || data.tiktok),
            data.creator_count >= 1,
            data.decription?.length > 1,
            data.delivery_item?.length > 1,
            new Date(data.start_date) > new Date(),


            loggedIn
        )
    }, [data])


    const submit = async () => {
        if (createdCampaign?.campaign && createdCampaign.paymentID) {
            setPaymentWindow(true)
        }
        try {
            setLoading(true)

            let body = data
            if(data.img){
                const images = await imageUpload(data.img)
                if (Array.isArray(images)) 
                    setData((prev) => ({ ...prev, img: null, image: images[0] }))
                    body = { ...body, image: images[0] }
            }   

            if(createdCampaign?.campaign?._id)
                    body = { ...body, _id: createdCampaign.campaign._id }


                const response = await createUpdateCampaign(body)
                const res = await response.json()
                if (response.status === 200) {
                    setCreatedCampaign(res)
                    setPaymentWindow(true)
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false)
        }

    return (
            <div className='relative flex flex-col justify-between h-full w-full gap-4'>
                <div className='flex flex-col gap-4 w-full h-full pb-20 overflow-y-scroll no-scrollbar'>
                    <div className='flex flex-col gap-1'>
                        <label className='text-themeBlack-900 text-sm font-medium'>{t('Media')}</label>
                        <div className='flex gap-2'>
                            <button onClick={() => {
                                if (!loggedIn) {
                                    setLogMeIn(true)
                                } else
                                    setData(pre => ({ ...pre, shorts: !pre.shorts }))
                            }} className={`outline-none flex-1 py-3 px-4 ${data.shorts ? 'border-themeBlack-900 text-themeBlack-900' : 'border-themeGray-400 text-themeBlack-100/50'} rounded-lg border flex justify-center items-center gap-1 text-sm font-normal`}>
                                <img src={'/shorts.png'} className='w-4 h-4' />
                                {t('shorts')}
                            </button>
                            <button onClick={() => {
                                if (!loggedIn) {
                                    setLogMeIn(true)
                                } else
                                    setData(pre => ({ ...pre, reels: !pre.reels }))
                            }} className={`outline-none flex-1 py-3 px-4 ${data.reels ? 'border-themeBlack-900 text-themeBlack-900' : 'border-themeGray-400 text-themeBlack-100/50'} rounded-lg border flex justify-center items-center gap-1 text-sm font-normal`}>
                                <img src={'/insta.png'} className='w-4 h-4' />
                                {t('reels')}
                            </button>
                            <button onClick={() => {
                                if (!loggedIn) {
                                    setLogMeIn(true)
                                } else
                                    setData(pre => ({ ...pre, tiktok: !pre.tiktok }))
                            }} className={`outline-none flex-1 py-3 px-4 ${data.tiktok ? 'border-themeBlack-900 text-themeBlack-900' : 'border-themeGray-400 text-themeBlack-100/50'} rounded-lg border flex justify-center items-center gap-1 text-sm font-normal`}>
                                <img src={'/tiktok.png'} className='w-4 h-4' />
                                {t('tiktok')}
                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col gap-1'>
                        <label className='text-themeBlack-900 text-sm font-medium'>{t('creators')}</label>
                        <Counter value={data.creator_count}
                            onChange={(e) =>
                                setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                            }
                            min={1} max={1000} name='creator_count' readOnly={readOnly} />
                    </div>

                    <Input type='number' name='additional_fee' onChange={onChange} readOnly={readOnly} value={data.additional_fee} label={t('additional_fee')} placeholder={t('100000')} />
                    <Input type='textarea' name='decription' onChange={onChange} readOnly={readOnly} value={data.decription} label={t('decription')} placeholder={t('enterdecription')} />
                    <Input type='text' name='delivery_item' onChange={onChange} readOnly={readOnly} value={data.delivery_item} label={t('delivery_item')} placeholder={t('enterdelivery_item')} />

                    <Sechudle readOnly={readOnly} data={data} setData={setData} />

                    <div className='bg-themeGray-400 rounded-lg px-6 py-4'>
                        <p className='text-themeBlack-100 text-xs'>{t('paymentText')}</p>
                    </div>
                </div>
                <div className='absolute bottom-0 w-full bg-white'>
                    <Button onClick={submit} text={t('checkout')} disabled={disabled} loading={loading} />
                </div>

                {paymentWindow && <PaymentModal setPaymentWindow={setPaymentWindow} createdCampaign={createdCampaign} />
                }
            </div>
        )
    }

    export default Column3
