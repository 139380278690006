import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/Auth';

function Counter({ value = 1, min = 1, max = 1, onChange, name, readOnly }) {
    const [count, setCount] = useState(value);
    const { loggedIn, setLogMeIn } = useAuth()
    const increment = () => {
        if (!loggedIn) {
            setLogMeIn(true)
        }
        else
        setCount((prevCount) => Math.min(prevCount + 1, max)); // Increment but do not exceed 1000
    };

    const decrement = () => {
        if (!loggedIn) {
            setLogMeIn(true)
        }
        else
            setCount((prevCount) => Math.max(prevCount - 1, min)); // Decrement but do not go below 1
    };

    const handleInputChange = (e) => {
        if (!loggedIn) {
            setLogMeIn(true)
            return;
        }
        let value = parseInt(e.target.value, 10);
        if (isNaN(value) || value < min) {
            value = min; // Default to 1 if invalid
        } else if (value > max) {
            value = max; // Cap at 1000
        }
        setCount(value);
    };

    useEffect(() => {
        onChange({
            target: {
                name: name,
                value: count
            }
        })
    }, [count]);

    return (
        <div className='flex justify-between items-center'>
            <button
                onClick={decrement}
                className='outline-none w-1/3 h-8 font-bold text-white cursor-pointer flex justify-center items-center bg-themeBlack-100/30 rounded-md'
            >
                -
            </button>
            <input
                type='number'
                value={count}
                readOnly={readOnly}
                onChange={handleInputChange}
                className='w-1/3 outline-none text-themeBlack-900 text-center text-base'
                min='1'
                max='1000'
            />
            <button
                onClick={increment}
                className='outline-none w-1/3 h-8 font-bold text-white cursor-pointer flex justify-center items-center bg-themeBlack-100/30 rounded-md'
            >
                +
            </button>
        </div>
    );
}

export default Counter;
