import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/Auth';

const TagInput = ({ onChange, name, value, label, readOnly = false }) => {
    const [tags, setTags] = useState(value? value : []);
    const [inputValue, setInputValue] = useState('');
    const [ReadOnly, setReadOnly] = useState(readOnly)

     const { loggedIn, setLogMeIn } = useAuth()
     const checkLogin = () => {
        if (!loggedIn) {
            setLogMeIn(true)
        }
     }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!tags.includes(inputValue.trim())) {
                setTags([...tags, inputValue.trim()]);
                setInputValue('');
            }
        } else if (e.key === 'Backspace' && !inputValue) {
            removeTag(tags.length - 1);
        }
    };

    const removeTag = (index) => {
        setTags(tags.filter((tag, i) => i !== index));
    };


    useEffect(() => {
        if (tags.length === 5) {
            setReadOnly(true)
        }
        else {
            setReadOnly(false)
        }
        onChange({
            target: {
                name,
                value: tags
            }
        })
    }, [tags])


    useEffect(() => {
        setTags(value)
    }, [value])

    return (
        <div className='flex flex-col gap-1 w-full'>
            {label && <label className='text-themeBlack-900 text-sm font-medium'>{label}</label>}
            <div className="flex flex-wrap w-full cursor-text px-4 py-3 border border-themeGray-400 rounded-lg" onClick={() => document.getElementById(name)?.focus()}>
                <div className="flex flex-wrap gap-2 ">
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            className="flex items-center px-1.5 bg-themeBlue-500/20 rounded-sm text-themeBlack-300 "
                        >
                            <span className="mr-3 text-xs leading-3">{name === "account_tags"? "@": "#"}{tag}</span>
                            {!readOnly && <button
                                className="text-themeGrey-300 hover:text-themeBlack-900"
                                onClick={() => removeTag(index)}
                            >
                                &times;
                            </button>}
                        </div>
                    ))}
                    {(!readOnly) && <input
                        type="text"
                        value={inputValue}
                        readOnly={ReadOnly || !loggedIn}
                        id={name}
                        onClick={checkLogin}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="flex-grow border-none outline-none resize-none text-themeGrey-150 text-sm min-w-[60px]"
                    />}
                </div>
            </div>
        </div>
    );
};

export default TagInput;