import React from 'react'
import Input from './Input'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { BASE_URL } from '../constants'
import { getMyProfile } from '../services/getMyProfile'
import { useAuth } from '../contexts/Auth'
import { useNavigate } from 'react-router-dom'
import { RxCross2 } from 'react-icons/rx'

function Login() {
    const [t] = useTranslation('global')
    const [logins, setLogins] = React.useState(
        localStorage.getItem('loggedInUser') ?
            JSON.parse(localStorage.getItem('loggedInUser'))
            :
            {
                email: '',
                password: ''
            }
    )
    const navigate = useNavigate()

    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [checkbox, setCheckbox] = React.useState(false)

    const { setLoggedIn, setUser, setLogMeIn } = useAuth()


    const login = async (e) => {
        e.preventDefault()
        if (loading) return
        setLoading(true)
        try {

            setError(null)
            const response = await fetch(`${BASE_URL}/user/login?role=customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(logins)
            })
            const res = await response.json()
            if (response.status === 200) {
                localStorage.setItem('token', res.token)
                const user = await getMyProfile()
                if (!user) {
                    localStorage.removeItem('token')
                    setError('Please try again')
                    setLoading(false)
                    return;
                }
                else {
                    setUser(user)
                    setLoggedIn(true)
                    setLogMeIn(false)
                }
                if (checkbox) {
                    localStorage.setItem('loggedInUser', JSON.stringify(logins))
                }
            }
            else if (response.status === 422) {
                setError(res.message)
            }
            else
                setError(res.message)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)

    }
    return (
        <div className='fixed top-0 w-full h-screen bg-black/70 flex justify-center items-center z-50'>

            <div className='flex flex-col gap-4 bg-white p-6 rounded-lg w-1/4'>
            <div className='flex justify-between items-center'>
                <h2 className='text-themeBlack-900 font-medium text-lg'>Login to <span className='font-bold'>Imfact</span><span className='font-normal text-sm'>.ai</span></h2>
                <RxCross2 onClick={() => setLogMeIn(false)} className='cursor-pointer' />
            </div>

                <form onSubmit={login} className='flex flex-col gap-3'>
                    <Input type="email" name="email" label={t("email")} placeholder={t("enteremail")} onChange={(e) => setLogins({ ...logins, email: e.target.value })} forcedEnabled={true}/>
                    <Input type="password" name="password" label={t("password")} placeholder={t("enterpassword")} onChange={(e) => setLogins({ ...logins, password: e.target.value })}  forcedEnabled={true}/>
                    <div className='flex justify-start items-center w-full gap-3 -mt-1'>
                        <input onChange={() => setCheckbox(!checkbox)} type="checkbox" className='checkbox sm:mt-0.5' checked={checkbox} />
                        <p className='text-xs text-themeBlack-100'>{t('remember_me')}</p>
                    </div>
                    {error && <p className='text-sm text-center text-red-600 sm:text-xs '>{error}</p>}
                    <Button
                        text={t('login')}
                        loading={loading}
                        disabled={logins.email?.length < 5 || logins.password?.length < 5}
                        onClick={login}
                    />
                      <div className=' w-full sm:mt-0 mt-32 left-0'>
                                <p
                                    onClick={() => {
                                        // navigate('/signup')
                                        setLogMeIn(false)
                                    }}
                                className='sm:text-center text-xs rounded-lg  flex justify-center items-center gap-1 text-themeBlack-100'>{t('dont_have_account')} 
                                    <span className='text-themeBlue-500 cursor-pointer'>{t('sign_up')}</span>.</p>
                            </div>
                </form>

            </div>

        </div>
    )
}

export default Login
