import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { RxCross2 } from "react-icons/rx";

function PaymentModal({ createdCampaign, setPaymentWindow }) {
    const [t] = useTranslation('global')
    const campaign = createdCampaign.campaign
    const paymentID = createdCampaign.paymentID

    const total = campaign.media_fee + campaign.additional_fee

    const portOne = async () => {

    }

    return (
        <div className='fixed top-0 left-0 w-full h-screen bg-black/70 flex justify-center items-center z-50'>
            <div className='flex flex-col gap-4 bg-white p-6 rounded-lg w-1/3'>
                <div className='flex justify-between items-center'>
                    <p className='text-base font-semibold text-themeBlack-900'>{t('payment')}</p>
                    <RxCross2 className='cursor-pointer' onClick={() => setPaymentWindow(false)} />
                </div>

                <div className='flex flex-col gap-1 border-b-[1px] pb-1 border-themeGray-400'>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('media_fee')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal">{campaign.media_fee.toLocaleString()}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('additional_fee')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal flex justify-end items-center gap-10">
                            <span className='text-lg'>+</span>
                            <span className='w-20 text-right'>{(campaign.additional_fee || 0).toLocaleString()}</span>
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-1 border-b-[1px] pb-1 border-themeGray-400'>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('percreator')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal">{total.toLocaleString()}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('creators')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal flex justify-end items-center gap-10">
                            <span className='text-lg'>*</span>
                            <span className='w-20 text-right'>{(campaign.creator_count).toLocaleString()}</span>
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-1 border-b-[1px] pb-1 border-themeGray-400'>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('total')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal">{campaign.amount.toLocaleString()}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="text-themeBlack-900 text-sm font-medium">{t('vat')}</p>
                        <p className="text-themeBlack-900 text-sm font-normal flex justify-end items-center gap-10">
                            <span className='text-lg'>+</span>
                            <span className='w-20 text-right'>{(campaign.vat).toLocaleString()}</span>
                        </p>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-themeBlack-900 text-sm font-medium">{t('grandTotal')}</p>
                    <p className="text-themeBlack-900 text-sm font-normal">{campaign.grand_total.toLocaleString()}</p>
                </div>

                <div className='flex justify-between items-center'>
                        <p className='text-themeBlack-100 text-xs'>{t('refuns')}</p>
                        <span className='w-1/3'>
                            <Button 
                                text={t('pay')}
                                onClick={portOne}
                            />
                        </span>
                </div>
            </div>
        </div>
    )
}

export default PaymentModal
