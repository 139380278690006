import React, { useEffect, useRef } from 'react';
import { FaRegDotCircle } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

function Select({
    options,
    name,
    onChange,
    placeholder,
    value,
    activeVal,
    readOnly = false,
    label
}) {
    const [active, setActive] = React.useState(false);
    const ref = useRef(null);
    console.log(options);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const selectedOption = value && options.filter(o => o.value === value)[0]

    return (
        <div className='flex flex-col gap-1 w-full relative z-10'>
            {label && <label className='text-themeBlack-900 text-sm font-medium'>{label}</label>}
            <div className={`relative w-full flex justify-start items-start gap-2 py-3 px-4 border-[1px] rounded-lg border-themeGray-400`} ref={ref}>
                <div onClick={() => { if (!readOnly) setActive(!active) }} className='flex justify-between items-center w-full cursor-pointer'>
                    <p className={`flex justify-start items-center gap-2 text-sm ${value?.length > 1 ? 'text-themeBlack-900' : 'text-themeBlack-100/50'} `}>
                        {selectedOption?.icon && <img src={selectedOption.icon} alt='icon' className='w-5 h-5 object-cover' />}
                        {selectedOption ? selectedOption.label : placeholder}
                    </p>
                    <IoIosArrowDown className='text-themeBlack-100/50 text-lg' />
                </div>

                {active && (
                    <div className={`absolute  right-0 top-10 w-full rounded-lg bg-white border-[1px] border-themeGray-400 z-40 shadow-md overflow-hidden mb-10`}>
                        <ul className='w-full h-full overflow-y-auto z-50 relative'>
                            {options.map((option, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        onChange({
                                            target: {
                                                name,
                                                value: option?.value
                                            }
                                        });
                                        setActive(false);
                                    }}
                                    className={`flex justify-between items-center py-2 px-4 gap-1 ${option?.value === value && 'bg-white'} hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300`}
                                >
                                    <div className={`flex justify-start items-center gap-2 ${option?.value === value? 'text-themeBlack-900' : 'text-themeBlack-100/50'} `}>
                                        {option.icon && <img src={option.icon} alt='icon' className='w-5 h-5 object-cover' />}
                                        {option?.label}
                                    </div>
                                    <FaRegDotCircle className={`${option?.value === value ? 'text-themeBlue-500' : 'text-themeBlack-100/50'} text-sm`} />
                                </li>
                            ))}
                        </ul>
                        <div className='absolute bottom-0 w-1/2 h-10 bg-themeBlue-500 blur-3xl opacity-60 z-0' />
                        <div className='absolute -top-2 right-0 w-1/2 h-10 bg-themeBlue-500 blur-3xl opacity-50 z-0' />
                    </div>
                )}
            </div>

        </div>
    );
}

export default Select;