

function Button({
    disabled,
    text,
    onClick,
    loading
}) {
    return (
        <button className={`w-full py-3 ${loading? 'opacity-70 cursor-wait' : 'disabled:bg-themeBlack-100/30 disabled:cursor-not-allowed'} text-white bg-themeBlue-500 rounded-lg font-medium text-sm`}
            disabled={disabled || loading}
            onClick={onClick}
        >
            {text}

        </button>
    )
}

export default Button
