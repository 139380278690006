import React from 'react'
import Sidebar from '../components/Sidebar'
import { useAuth } from '../contexts/Auth'
import { Link, NavLink } from 'react-router-dom'

function MainLayout({ children }) {
  const { loggedIn, setUser, setLoggedIn, setLogMeIn } = useAuth()
  return (
    <div className='flex justify-start items-stretch h-screen py-6 px-6 gap-6'>
      <Sidebar />
      <div className='flex flex-col w-full'>
        <div className='flex mb-6 justify-end items-start gap-4'>
            {loggedIn ?
              <>
                <Link to='/billing' className={'text-themeBlue-500 font-bold text-sm mx-2 hover:underline'}>Billing</Link>
                <p
                  onClick={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    window.location.reload()
                    setLoggedIn(false)
                    setUser(null)

                  }}
                className={'text-themeBlue-500 font-bold text-sm mx-2 hover:underline'}>Profile</p>
              </>
              :
              <>
              <span
                onClick={() => setLogMeIn(true)}
              className='w-[100px] flex justify-center border-2 border-themeBlue-500 px-4 py-1 font-bold text-themeBlue-500 rounded-full text-sm cursor-pointer'>
                Login 
              </span>
              <span className='w-[100px] flex justify-center border-2 bg-themeBlue-500 border-themeBlue-500 px-4 py-1 font-bold text-white cursor-pointer rounded-full text-sm'>
                Register 
              </span>
              </>
            }
        </div>
        <div className='h-[calc(100vh-5rem)] overflow-hidden'>
          {children}

        </div>
      </div>
    </div>
  )
}

export default MainLayout
