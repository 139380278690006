

import React, { useEffect, useState } from 'react'
import Campaigns from '../components/Campaigns'
import CampaignDetail from '../components/CampaignDetail'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getMyCampaigns } from '../services/getMyCampaigns'
import Loader from '../components/Loader'

function Track() {
    const [t] = useTranslation("global")
    const [campaigns, setCampaigns] = React.useState([])
    const [loading, setLoading] = useState(true)
    const fetchCampaigns = async () => {
        try {
            const data = await getMyCampaigns()
            setCampaigns(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchCampaigns()
    }, [])
    return (
        <div className='flex w-full h-full justify-start items-stretch gap-6'>
            <div className='flex w-1/3 bg-white p-6 rounded-md no-scrollbar'>
                <Campaigns campaigns={campaigns} loading={loading} />
            </div>
            <div className='flex flex-1 bg-white p-6 rounded-md no-scrollbar'>
            <h2 className='font-semibold text-themeBlack-900'>{t('creators')}</h2>
            </div>
            <div className='flex flex-1 bg-white p-6 rounded-md no-scrollbar'>
            <h2 className='font-semibold text-themeBlack-900'>{t('index')}</h2>
            </div>
        </div>
    )
}

export default Track
