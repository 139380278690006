import React, { useEffect } from 'react'
import Input from '../components/Input'
import Column1 from '../components/create/Column1'
import Column2 from '../components/create/Column2'
import Column3 from '../components/create/Column3'
import { imageUpload } from '../services/imageUpload'
import { generateChatGPT } from '../services/generateChatGPT'


function Create() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [generating, setGenerating] = React.useState(false)
  const [generated, setGenerated] = React.useState(false)

  const [data, setData] = React.useState({
    period: 7,
    start_date: tomorrow.toISOString().split('T')[0]
  })

  const generateWithAI = async() => {
    try {
      setGenerating(true)

      const images = await imageUpload(data.img)
      if(Array.isArray(images)){
        setData((prev) => ({...prev, image: images[0]}))

        const {  
          productSummary,
          hashtags,
          keyPoints,
          cautions,
          tips,
          Decription,
         } = await generateChatGPT(data.product_url, images[0], data.mode || '')

          setData((prev) => ({
            ...prev,
            summary: productSummary,
            hash_tags: hashtags,
            key_point: keyPoints,
            caution: cautions,
            tips: tips,
            decription: Decription
          }))
          setGenerated(true)
      }
    } catch (error) {
      
    }
    setGenerating(false)
  }

  useEffect(() => {
    if (data.start_date && !isNaN(new Date(data.start_date).getTime())) {
        const submission = new Date(data.start_date);
        const period = Number(data.period);
        submission.setDate(submission.getDate() + period);

        const buffer = new Date(submission);
        buffer.setDate(buffer.getDate() + 14);

        setData((prev) => ({
            ...prev,
            submission_date: submission.toISOString().split('T')[0],
            buffer_date: buffer.toISOString().split('T')[0]
        }))
      
    }
}, [data.start_date, data.period]);

  return (
    <div className='flex w-full h-full justify-start items-stretch gap-6'>
      <div className='flex flex-1 bg-white p-6 rounded-md'>
         <Column1 data={data} readOnly={false} setData={setData} generateWithAI={generateWithAI} generating={generating} generated={generated}/>
      </div>
      <div className='flex flex-1 bg-white p-6 rounded-md'>
          <Column2 data={data} readOnly={false} setData={setData} generateWithAI={generateWithAI} generating={generating} generated={generated}/>
      </div>
      <div className='flex flex-1 bg-white p-6 rounded-md'>
          <Column3 data={data} readOnly={false} setData={setData} />
      </div>
    </div>
  )
}

export default Create
