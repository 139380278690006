import React from 'react'
import { useTranslation } from 'react-i18next'

function CampaignDetail() {
    const [t] = useTranslation("global")
    return (
        <div>
            <h2 className='font-semibold text-themeBlack-900'>{t('creators')}</h2>
        </div>
    )
}

export default CampaignDetail
